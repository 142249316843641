<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-form-group>
                <b-form-radio-group
                  id="radio-group-3"
                  v-model="type"
                  name="radio-sub-component"
                >
                  <b-form-radio name="some-radios" value="channelpartner"
                    >ChannelPartner</b-form-radio
                  >
                  <b-form-radio name="some-radios1" value="franchise"
                    >Franchise</b-form-radio
                  >
                  <b-form-radio name="some-radios1" value="ApproveOrRejectUsers"
                    >Approve or Reject Users</b-form-radio
                  >
                </b-form-radio-group>
              </b-form-group>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/getpendingcplist`"
                title="KYC"
                :columns="columns"
                routerpath="/master/crm/addchannelpartner"
                :formOptions="formOptions"
                :kyc="true"
                :newPage="true"
                :style="type == 'channelpartner' ? '' : 'display:none'"
              >
                <template #action="{ row, getGridData }">
                  <action :row="row" :getGridData="getGridData" />
                </template>

                <template #customField="{ props }">
                  <span v-if="props.column.field == 'is_kyc'">
                    <b-badge
                      :variant="
                        statusVariant(
                          props.row.is_kyc == 0
                            ? 'Pending'
                            : props.row.is_kyc == 1
                            ? 'Accepted'
                            : 'Rejected'
                        )
                      "
                    >
                      {{
                        props.row.is_kyc == 0
                          ? "Pending"
                          : props.row.is_kyc == 1
                          ? "Accepted"
                          : "Rejected"
                      }}
                    </b-badge>
                  </span>
                </template>
              </good-table-column-search>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/getpendingfrlist`"
                title="KYC"
                :columns="columns"
                routerpath="/master/crm/addfranchisr"
                :formOptions="formOptions"
                :kyc="true"
                :newPage="true"
                :style="type == 'franchise' ? '' : 'display:none'"
              >
                <template #action="{ row, getGridData }">
                  <action :row="row" :getGridData="getGridData" />
                </template>

                <template #customField="{ props }">
                  <span v-if="props.column.field == 'is_kyc'">
                    <b-badge
                      :variant="
                        statusVariant(
                          props.row.is_kyc == 0
                            ? 'Pending'
                            : props.row.is_kyc == 1
                            ? 'Accepted'
                            : 'Rejected'
                        )
                      "
                    >
                      {{
                        props.row.is_kyc == 0
                          ? "Pending"
                          : props.row.is_kyc == 1
                          ? "Accepted"
                          : "Rejected"
                      }}
                    </b-badge>
                  </span>
                </template>
              </good-table-column-search>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/getapprovedusers`"
                title="KYC"
                :columns="columns.slice(0, columns.length - 1)"
                routerpath="/master/crm/addfranchise"
                :formOptions="formOptions"
                :kyc="true"
                :newPage="true"
                :useRowColors="true"
                :style="type == 'ApproveOrRejectUsers' ? '' : 'display:none'"
              >
                <template #customField="{ props }">
                  <span v-if="props.column.field == 'is_kyc'">
                    <b-badge
                      :variant="
                        statusVariant(
                          props.row.is_kyc == 0
                            ? 'Pending'
                            : props.row.is_kyc == 1
                            ? 'Accepted'
                            : 'Rejected'
                        )
                      "
                    >
                      {{
                        props.row.is_kyc == 0
                          ? "Pending"
                          : props.row.is_kyc == 1
                          ? "Accepted"
                          : "Rejected"
                      }}
                    </b-badge>
                  </span>
                </template>
              </good-table-column-search>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import action from "./action.vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import { BFormRadio, BFormRadioGroup, BFormGroup, BBadge } from "bootstrap-vue";

export default {
  components: {
    GoodTableColumnSearch,
    action,
    BFormRadio,
    BFormRadioGroup,
    BFormGroup,
    BBadge,
  },
  data() {
    return {
      type: "channelpartner",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      columns: [
        {
          label: "Username",
          field: "srno",
          filterOptions: {
            enabled: true,
            placeholder: "Search Username",
          },
        },
        {
          label: "Name",
          field: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search name",
          },
        },
        {
          label: "Mobile",
          field: "mobile",
          filterOptions: {
            enabled: true,
            placeholder: "Search Mobile",
          },
        },
        {
          label: "Email",
          field: "email",
          filterOptions: {
            enabled: true,
            placeholder: "Search Email",
          },
        },
        {
          label: "Bank",
          field: "bankname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Bank",
          },
        },
        {
          label: "Branch",
          field: "bankbranch",
          filterOptions: {
            enabled: true,
            placeholder: "Search Branch",
          },
        },
        {
          label: "Status",
          field: "is_kyc",
          filterOptions: {
            enabled: true,
            placeholder: "Search Branch",
          },
        },
        {
          label: "Remarks",
          field: "specialremarks",
          filterOptions: {
            enabled: true,
            placeholder: "Search Remarks",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",

          responseValue: "fullname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
         {
          label: "Enter Date",
          field: "enterdatetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          responseValue: "fullname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      formOptions: {
        submitRouterPath: "/setup/language",
        formTitle: "KYC",
        usersDropdown: true,
        method: "post",
        action: "add",
        gridForm: true,
        url: `${baseApi}/language`,
        inputFields: [
          {
            name: "branch",
            value: 1,
            type: "hidden",
          },
          {
            name: "company",
            value: 1,
            type: "hidden",
          },
          {
            name: "active",
            value: 1,
            type: "hidden",
          },
          {
            label: "Language Name",
            name: "name",
            value: "",
            type: "text",
            placeholder: "Enter Language Name",
            class: "col-md-12",
            required: true,
          },
        ],
      },
    };
  },
  mounted() {
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (
      role !== "admin" &&
      !this.$store.getters["app/widgetAccess"]("KYC") &&
      !this.$store.getters["app/approvalAccess"]("KYC")
    ) {
      this.$router.push("/");
    }
    if (this.$route.query.selected) {
      this.type = this.$route.query.selected;
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
